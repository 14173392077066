import React from 'react'
import { PageProps, graphql } from 'gatsby'
import Slider from 'react-slick'
// import { SRLWrapper } from 'simple-react-lightbox'

import { ProjectT, ProjectImageT } from '../common'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { SVG, easing } from '@svgdotjs/svg.js'
import getAbsoluteOffset from '../utils/getAbsoluteOffset'
import useElementSize from '../hooks/useElementSize'
import { kebabCase } from 'lodash-es'
import useHeaderLines from '../hooks/useHeaderLines'

import ArrowLeft from '../images/chevron-left.svg'
import ArrowRight from '../images/chevron-right.svg'

type Data = {
  markdownRemark: {
    frontmatter: ProjectT
    html: string | null | undefined
  }
}

const sliderSettings = {
  dots: false,
  infinite: true,
  // autoplay: true,
  // autoplaySpeed: 2000,
  // pauseOnHover: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  className: 'project-post__hero-slide',
}

const SRLOptions = {
  settings: {},
  caption: {},
  buttons: {},
  thumbnails: {},
  progressBar: {},
}

const ProjectTemplate: React.FC<PageProps<Data>> = ({ data, location }) => {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const heroImage = frontmatter.images.filter(
    (i: ProjectImageT) => i.featured === true,
  )[0]
  // const designIntro = frontmatter.design[0] || ''
  // const designContent = frontmatter.design.slice(1)
  const sliderRef = React.useRef(null)
  const pageRef = React.useRef(null)
  const size = useElementSize(pageRef)
  const headerLines = useHeaderLines()

  React.useEffect(() => {
    if (pageRef.current && size) {
      const headerRect = getAbsoluteOffset(document.querySelector('.header'))
      const pageRect = getAbsoluteOffset(
        document.querySelector(
          `.page--${kebabCase(frontmatter.title.toLowerCase())}`,
        ),
      )
      const breadcrumbRect = getAbsoluteOffset(
        document.querySelector('.breadcrumb-container'),
      )
      const projectImageRect = getAbsoluteOffset(
        document.querySelector('.slick-slider'),
      )
      const infoServicesRect = getAbsoluteOffset(
        document.querySelector('.project-post__info'),
      )
      const introSectionRect = getAbsoluteOffset(
        document.querySelector('.section--intro'),
      )

      const svg = SVG()
        .addClass('topline')
        .addTo('body')
        .size('100%', document.body.clientHeight)
        .stroke({
          color: '#000',
          width: 5,
          miterlimit: 10,
        })

      svg.polyline([
        ...headerLines,

        pageRect.left,
        headerRect.bottom,

        pageRect.left,
        breadcrumbRect.bottom,

        pageRect.right,
        breadcrumbRect.bottom,

        pageRect.right,
        projectImageRect.bottom,

        document.body.clientWidth,
        projectImageRect.bottom,
      ])

      svg.polyline([
        0,
        infoServicesRect.top + infoServicesRect.height / 2,

        pageRect.left,
        infoServicesRect.top + infoServicesRect.height / 2,

        pageRect.left,
        introSectionRect.bottom,

        pageRect.right,
        introSectionRect.bottom,
      ])

      return () => svg.remove()
    }
  }, [size])

  const sliderNext = () => {
    const _this = sliderRef.current
    _this.slickNext()
  }
  const sliderPrev = () => {
    const _this = sliderRef.current
    _this.slickPrev()
  }

  return (
    <Layout
      breadcrumbs={[
        { name: 'Projects', to: '/projects', color: '#ff4b3d' },
        { name: 'Workplace Design', color: '#a59088' },
        { name: frontmatter.title, color: '#00c1c1' },
      ]}
    >
      <SEO title={frontmatter.title} />
      <main
        ref={pageRef}
        className={`container page--${kebabCase(
          frontmatter.title.toLowerCase(),
        )}`}
      >
        {/* <SRLWrapper> */}
        <article className="project-post">
          <div className="project-post__slider">
            <Slider {...sliderSettings} ref={sliderRef}>
              {frontmatter.video &&
                frontmatter.video.map((video, i) => (
                  <iframe
                    src={`https://www.youtube.com/embed/${video.id}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                    frameBorder="0"
                    style={{ border: 0 }}
                    key={i}
                  />
                ))}
              {frontmatter.images &&
                frontmatter.images.map((image, i) => (
                  <img src={image.url} alt={image.alt || ''} key={i} />
                ))}
            </Slider>
            <div className="project-post__slider-nav">
              <button
                className="project-post__slider-button project-post__slider-button--prev"
                onClick={sliderPrev}
              >
                <ArrowLeft />
              </button>
              <button
                className="project-post__slider-button project-post__slider-button--next"
                onClick={sliderNext}
              >
                <ArrowRight />
              </button>
            </div>
          </div>

          {/* <section className="project-post__hero-image">
            <img src={heroImage.url} alt={heroImage.alt} />
          </section> */}
          <section className="project-post-meta">
            <header className="project-post__header">
              <h1
                className="title"
                dangerouslySetInnerHTML={{ __html: frontmatter.title }}
              />
              {frontmatter.tags && (
                <h4 className="cats">
                  {frontmatter.tags.map((tag: string, i: number) => (
                    <span key={i} className="cat">
                      {tag}
                    </span>
                  ))}
                </h4>
              )}
            </header>
            <div className="project-post__info">
              {frontmatter.info && (
                <>
                  {frontmatter.info.services && (
                    <div className="info info--services">
                      <div className="info__label">Services</div>
                      <div className="info__value">
                        {frontmatter.info.services.map(
                          (s: string, i: number) => (
                            <span key={i}>{s}</span>
                          ),
                        )}
                      </div>
                    </div>
                  )}
                  {frontmatter.info.location && (
                    <div className="info info--location">
                      <div className="info__label">Location</div>
                      <div className="info__value">
                        {frontmatter.info.location.map(
                          (l: string, i: number) => (
                            <span key={i}>{l}</span>
                          ),
                        )}
                        {/* {frontmatter.info.location} */}
                      </div>
                    </div>
                  )}
                  {frontmatter.info.sector && (
                    <div className="info info--sector">
                      <div className="info__label">Sector</div>
                      <div className="info__value">
                        {frontmatter.info.sector}
                      </div>
                    </div>
                  )}
                  {frontmatter.info.area && (
                    <div className="info info--area">
                      <div className="info__label">Area</div>
                      <div className="info__value">{frontmatter.info.area}</div>
                    </div>
                  )}
                </>
              )}
            </div>
          </section>
          <section className="section section--intro">
            <h2 className="section__title">Introduction</h2>
            <div
              className="section__content"
              dangerouslySetInnerHTML={{ __html: frontmatter.intro }}
            />
          </section>
          <section className="section section--brief-vision grid grid-col-2">
            {frontmatter.brief && (
              <section className="subsection subsection--brief">
                <h2 className="subsection__title">The Brief</h2>
                <div
                  className="subsection__content"
                  dangerouslySetInnerHTML={{ __html: frontmatter.brief }}
                />
              </section>
            )}
            {frontmatter.vision && (
              <section className="subsection subsection--vision">
                <h2 className="subsection__title">The Vision</h2>
                <div
                  className="subsection__content"
                  dangerouslySetInnerHTML={{ __html: frontmatter.vision }}
                />
              </section>
            )}
          </section>
          {frontmatter.design && (
            <section className="section section--design">
              <h2 className="section__title">The Design</h2>
              {frontmatter.design.map((content: string, index: number) => (
                <div
                  key={index}
                  className="section__content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              ))}
            </section>
          )}
          {frontmatter.solution && (
            <section className="section section--solution">
              <h2 className="section__title">The Solution</h2>
              {frontmatter.solution.map((content: string, index: number) => (
                <div
                  key={index}
                  className="section__content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              ))}
            </section>
          )}
        </article>
        {/* </SRLWrapper> */}
      </main>
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        intro
        vision
        tags
        brief
        categories
        info {
          area
          sector
          location
          services
        }
        video {
          id
          featured
        }
        images {
          alt
          featured
          url
        }
        design
        solution
      }
    }
  }
`
